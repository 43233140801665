
import { defineComponent, ref } from "vue";
import Axios from "@/core/services/Http";
import { Notify } from "@/core/services";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Archived Orders",
	components: {
		InnerLoader,
	},
	setup() {
		const i18n = useI18n();
		const economic_number = ref("");
		const loading = ref(false);

		const processArchivedOrder = async () => {
			if (economic_number.value != "") {
				const formData = {
					economic_number: economic_number.value
				};
				loading.value = true;
				try {
					const response = await Axios.post("/economic/process-archived-order", formData);
					loading.value = false;
					if (response.data.status == 404) {
						Notify.error(i18n.t("message.ARCHIVED_ORDER_NOT_PROCESSED"));
						return;
					}
					Notify.success(i18n.t("message.ARCHIVED_ORDER_PROCESS_SUCCESSFULLY"));
					economic_number.value = ''
				} catch ($e) {
					Notify.error($e);
					loading.value = false;
				}
			}
		};

		return {
			loading,
			economic_number,
			processArchivedOrder,
		};
	},
});
