
import { defineComponent, ref, toRefs } from 'vue';
import Axios from '@/core/services/Http';
import { Notify } from '@/core/services';
import { Modal } from 'bootstrap';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'Individual Customers',
    components: {
        InnerLoader
    },
    props: {
        country_id: String
    },
    setup(props) {
        const modal: any = ref();
        const i18n = useI18n();
        const reports = ref([]) as Record<any, any>;
        const economic_number = ref('');
        const { country_id } = toRefs(props);
        const loading = ref(false);

        const getIndividualProduct = async () => {
            if (country_id?.value == '') {
                Notify.error(`${i18n.t('message.COUNTRY')} ${i18n.t('message.IS_REQUIRED')}`);
                return;
            }
            if (economic_number.value != '') {
                const formData = {
                    economic_number: economic_number.value,
                    country_code: country_id?.value
                };
                loading.value = true;
                try {
                    const response = await Axios.post('/economic/get-product', formData);
                    loading.value = false;
                    if (response.data.status == 404) {
                        Notify.error(i18n.t('message.PRODUCT'));
                        return;
                    }
                    if (response?.data?.report?.report?.length > 0) {
                        reports.value = response.data.report.report;
                        modal.value = new Modal(document.getElementById('show_report_for_individual_product')) as HTMLElement;
                        modal.value.show();
                    } else {
                        Notify.success(i18n.t('message.PRODUCTS_SYNC_SUCCESSFULLY'));
                    }
                } catch ($e) {
                    Notify.error($e);
                    loading.value = false;
                }
            }
        };

        return {
            loading,
            economic_number,
            reports,
            getIndividualProduct
        };
    }
});
