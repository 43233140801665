
import { defineComponent, onMounted, ref } from 'vue';
import { Apollo } from '@/core/services';
import { GET_COUNTRY_WITH_SITE } from '@/modules/economic/graphql/Queries';
import CustomerGroup from '../components/partials/CustomerGroups.vue';
import Customers from '../components/partials/Customers.vue';
import IndividualCustomer from '../components/partials/IndividualCustomer.vue';
import IndividualProduct from '../components/partials/IndividualProduct.vue';
// import Products from '../components/partials/Products.vue';
import PriceGroup from '../components/partials/PriceGroup.vue';
import ArchivedOrder from '../components/partials/ArchivedOrder.vue';
// import ImportPriceGroups from '../components/partials/ImportPriceGroups.vue';
import EconomicLoader from './EconomicLoader.vue';

export default defineComponent({
    name: 'languages',
    components: {
        // Customers,
        IndividualCustomer,
        CustomerGroup,
        // Products,
        IndividualProduct,
        // PriceGroup,
        ArchivedOrder,
        // ImportPriceGroups,
        EconomicLoader
    },
    setup() {
        const loader = ref(false);
        const countries = ref([]) as Record<any, any>;
        const country_id = ref('');

        const getCountries = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_COUNTRY_WITH_SITE,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                const response = JSON.parse(data.country_with_site);
                const temp = ref([]) as Record<any, any>;
                response.forEach(ele => {
                    temp.value = [];
                    if (ele.sites) {
                        ele.sites.forEach(element => {
                            temp.value.push({
                                label: element.name,
                                value: `${ele.iso_code_3}-${element.economic_account_id}-${element.id}`
                            });
                        });
                    }
                    countries.value.push({
                        label: ele.name,
                        options: temp.value
                    });
                });
                loader.value = false;
            });
        };

        onMounted(() => {
            getCountries();
        });

        return {
            country_id,
            countries,
            loader
        };
    }
});
